.card {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
  border: transparent;
  margin-bottom: 1.875rem;
  transition: all 300ms ease;

  .card-header {
    font-weight: 300;
    padding: 15px 20px 15px;
    position: relative;
    border-bottom: 1px solid rgba(234,243,253,.9);
    background-color: transparent;
    min-height: 45px;

    .card-title {
      font-weight: 300;
    }
  }

  .card-footer {
    padding: 1rem 1.25rem;
    font-size: 1.1rem;
    font-weight: 400;
    background-color: transparent;
    border-top: 1px solid rgba(234, 243, 253,.7);
  }
}

.custom-typeahead .dropdown-item.active {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.label-filter-date {
  text-align: right;
}
.label-filter-date > label {
  padding-top: .3rem;
  margin-bottom: 0;
  font-size: .875rem;
}

//.date-range-inputs-container {
//  display:         block;
//  flex-flow:       row wrap;
//  justify-content: flex-start;
//  align-items:  stretch;
//}

.input-group-text {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-group-prepend.no-background {

  & > .input-group-text {
    background-color: $white;
    border-color: $input-border-color;
    border-right: none;

    & > .form-label {
      margin-bottom: 0;
    }
  }
  & + .form-control, & + .rbt > .form-control {
    border-left: none;
    padding-left: map-get($spacers, 2);
  }
}

.input-group {
  .btn {
    height: 32px;
  }
  &:focus-within:not(.invalid) {
    & .input-group-text {
      border-color: $primary;
    }

    & .form-control {
      border-color: $primary;
    }
  }
  &.invalid {
    & .input-group-text {
      border-color: $danger;
    }
  }
}

.custom-control {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.custom-checkbox {
    align-items: center;
    padding-left: 0;

    .custom-control-input[type="checkbox"] ~.custom-control-label{
      display: flex;
      flex-flow: row;
      align-items: center;
      flex-grow: 1;

      &:after {
        content: none;
      }

      & > span.label {
        flex-grow: 1;
      }
      &:before {
        position: unset;
        margin-right: $spacer / 2;
        min-width: 1rem;
        min-height: 1rem;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .custom-control-input[type="checkbox"]:indeterminate ~ .custom-control-label:before {
      background-image: url("data:image/svg+xml,%3Csvg width='7' height='2' viewBox='0 0 7 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline y1='1' x2='7' y2='1' stroke='white'/%3E%3C/svg%3E%0A");
    }

    .custom-control-input[type="checkbox"]:checked ~ .custom-control-label:before {
      background-image: escape-svg($custom-checkbox-indicator-icon-checked);
    }
  }

  &:hover {
    .custom-control-input:not(:checked):not(:indeterminate) ~ .custom-control-label:before {
      background-color: $primary-200;
    }
  }
}



.custom-control-label {
  line-height: 1rem;
  &::before, &::after {
    top: 0;
  }
}

.top-notification{
  background: #FADB9E;
  color: $gray-800;
  flex: 0 0 28px;
  display: flex;
  justify-content: center;
  border-bottom: solid 1px #F7C96E;
  height: 28px;
  align-items: center;
  &>a{
    &>i{
      margin-top: -2px;
      width: 7px;
      height: 10px;
      background-image: url("data:image/svg+xml,%3Csvg width='7' height='10' viewBox='0 0 7 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.158203 8.825L3.97487 5L0.158203 1.175L1.3332 0L6.3332 5L1.3332 10L0.158203 8.825Z' fill='%231F4D93'/%3E%3C/svg%3E%0A");
      margin-right: 8px;
      color: $primary-700 !important;
    }
    margin-left: 12px;
    color: $primary-700;
    font-weight: 500;
  }

}

.hr-display {
  .hr-form {
    .row {
      align-items: center;
    }
    .col-form-label {
      padding-left: 0;
      color: $gray-400;
      @extend .typography-default-text-title;
    }
  }

  .hr-results {
    h1 {
      font-size: $h6-font-size;
      line-height: 120%;
      display: flex;
      align-items: center;
      letter-spacing: -0.01em;
      color: $white;
      background-color: $primary-700;
      border-radius: 4px;
      padding: $spacer/2 $spacer/1.5;
      margin: $spacer * 2 0 $spacer 0;
    }
  }
}

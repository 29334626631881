.login-card-header{
  background: url(/images/logo/login-header.png);
  background-size: 521px 130px;
  height:130px;
}

.card.login-card{
  border-radius: 10px;
  width: 521px;
}
.login-card-content{
  width:400px;
}

.login-card-title{
  margin-bottom: 33px;
}

.login-card .card-body{
  padding-bottom: 60px;
}

.login-card button[type="submit"]{
  margin-top:40px;
}

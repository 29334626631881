$input-color:                                        $gray-900;
$input-border-color:                                 $gray-100;
$input-focus-border-color:                           $primary;
$input-font-size:                                    $font-size-sm;
$input-font-size-sm:                                 $font-size-sm;
$input-font-size-lg:                                 $font-size-base;
$input-placeholder-color:                            $gray-400;
$input-disabled-bg:                                  $gray-200;

$custom-control-indicator-bg:                        $white;
$custom-control-indicator-border-color:              $gray-300;
$custom-control-indicator-border-width:              1px;
$custom-control-indicator-checked-bg:                $primary-700;
$custom-control-indicator-checked-disabled-bg:       $gray-200;
$custom-control-indicator-focus-border-color:        $primary-400;

$custom-checkbox-indicator-indeterminate-bg:           $primary-700;
$custom-checkbox-indicator-indeterminate-border-color: $gray-300;
$custom-checkbox-indicator-indeterminate-border-color: $primary-700;
$custom-checkbox-indicator-border-radius: 4px;
$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 3.5L3.5 6.5L9 1' stroke='white'/%3E%3C/svg%3E%0A");
$custom-checkbox-indicator-icon-indeterminate: url("data:image/svg+xml,%3Csvg width='7' height='2' viewBox='0 0 7 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline y1='1' x2='7' y2='1' stroke='white'/%3E%3C/svg%3E%0A");

$custom-radio-indicator-icon-checked: url("data:image/svg+xml,%3Csvg width='7' height='8' viewBox='0 0 7 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='0.5' width='7' height='7' rx='3.5' fill='white'/%3E%3C/svg%3E%0A");

$input-group-addon-bg:                               $primary-100;
$input-group-addon-color:                            $gray-600;

$input-padding-y: 0rem;
$input-height: 32px;
$input-height-lg: 40px;
$input-padding-lg: 0.5rem;
$input-padding-lg: 1rem;
$input-padding-y-lg: 0.6875rem;
$input-padding-x-lg: 1rem;
$input-font-size-lg: 0.875rem;
$input-line-height-lg: 130%;
$input-border-color: $gray-200;
$input-placeholder-color: $gray-300;

$input-color: $gray-600;

$input-focus-border-color: #1F4D93;

.react-contexify {
  position: fixed;
  opacity: 0;
  user-select: none;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 10px 7px;
  //min-width: 200px;
  z-index: 100;

  &__submenu--is-open,
  &__submenu--is-open > &__item__content {
    background-color: $primary-200;
    border-radius: 4px;
    color: $gray-500;
  }

  &__submenu--is-open > &__submenu {
    pointer-events: initial;
    opacity: 1;
  }

  & &__submenu {
    position: absolute;
    /* negate padding */
    top: -6px;
    pointer-events: none;
    transition: opacity 0.275s;
  }

  &__submenu-arrow {
    margin-left: auto;
    font-size: 12px;
  }

  &__separator {
    overflow: hidden;
    border-top: 1px solid #DEE5EF;
    margin: 7px 10px;
    cursor: default;
  }

  &__will-leave--disabled {
    pointer-events: none;
  }

  &__item {
    cursor: pointer;
    position: relative;

    &:focus {
      outline: 0;
    }

    &:not(&--disabled):hover > &__content,
    &:not(&--disabled):focus > &__content {
      background-color: $primary-200;
      border-radius: 4px;
      color: $gray-500;
    }

    &:not(&--disabled):hover > .react-contexify__submenu {
      pointer-events: initial;
      opacity: 1;
    }

    &--disabled {
      cursor: default;
      opacity: 0.35;
    }

    &__content {
      padding: 7px 10px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      position: relative;
    }
  }
}

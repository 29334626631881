.form-label {
  font-weight: 500;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.00008 0.666626C4.40008 0.666626 0.666748 4.39996 0.666748 8.99996C0.666748 13.6 4.40008 17.3333 9.00008 17.3333C13.6001 17.3333 17.3334 13.6 17.3334 8.99996C17.3334 4.39996 13.6001 0.666626 9.00008 0.666626ZM9.83342 13.1666H8.16675V11.5H9.83342V13.1666ZM9.83342 9.83329H8.16675V4.83329H9.83342V9.83329Z' fill='%23CE3E3E'/%3E%3C/svg%3E%0A");
}

.form-control {
  font-size: $font-size-base * 1.17 !important;
}

.form-control-sm {
  font-size: $font-size-base !important;
}

.form-control-lg {
  font-size: $font-size-base * 1.33 !important;
}

.input-group {
  &.input-group-lg{
    .input-group-prepend {
      height: 40px;
    }
  }
  .input-group-prepend {
    height: 32px;
    & ~ .form-control {
      border-left: none;
      &:focus{
        box-shadow: none;
      }
    }

    & > .input-group-text {
      padding-left: 8px;
      padding-right: 8px;
      border-right: none;
      font-size: $font-size-base * 1.17 !important;
    }
  }
  &.input-group-sm .input-group-prepend > .input-group-text {
    font-size: $font-size-base !important;
  }
  &.input-group-lg .input-group-prepend > .input-group-text {
    font-size: $font-size-base * 1.33 !important;
  }
}

input.form-control:after{
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 5px;
  border: 2px solid #ccc;
}

select option[disabled] {
  color: $input-placeholder-color;
}

input.form-control:focus{
  box-shadow: 0 0 2px 2px $primary-300;
}
input.form-control:disabled{
  color: $gray-400;
}

.invalid-feedback {
  font-size: $font-size-base * .875;
}

.custom-input-file {
  cursor: pointer;
  min-height: 32px !important;
}

html, body, #root, #app {
  height: 100vh;
  max-height: 100vh;

  display: flex;
  flex-flow: column;
  letter-spacing: .02rem;
}

body, #root, #app {
  flex-grow: 1;
}

*, :active, :focus {
  outline: 0;
}

label, p, ol li, ul li {
  font-size: $font-size-base;
}

.w-fit-content {
  width: fit-content !important;
}

.admin-container {
  display: flex;
  flex-flow: row;
  gap: $spacer * 2;
  flex-grow: 1;


  &.fixed-width-input .form-control {
    width: 300px;
  }

  .admin-content {
    flex-grow: 1;
    margin-top: 34px;
    margin-right: 81px;
    padding-bottom: $spacer * 2;

    .form-row {
      margin-bottom: 20px;
    }

    .form-group {
      i {
        margin-top: -3px;
      }
    }

    h6 {
      margin-bottom: 20px;
    }

    h4 {
      color: $primary-700;

      &:after {
        content: '';
        display: block;
        width: 30px;
        height: 2px;
        background-color: $primary-700;
        margin-top: 20px;
      }
    }

    .admin-content-divider {
      width: 100%;
      height: 1px;
      background-color: $gray-200;
      margin: 48px 0px 48px 0px;
    }
  }

  .admin-menu {
    flex: 0 0 33%;
    max-width: 33%;
    height: 100%;
    padding-top: 52px;
    font-weight: 500;
    background-color: $sand-100;

    .admin-menu-divider {
      width: calc(100% - 118px);
      height: 2px;
      background-color: $sand-300;
      margin: 12px 59px 12px;
    }

    &.nav {
      .nav-item {
        & > a {
          color: $sand-700;
        }

        & > .nav-link {
          padding: 12px 59px 12px 57px;
          border-left: solid 3px transparent;

          &.active {
            color: $primary-700;
            border-left: solid 3px $primary-700;
          }
        }
      }
    }
  }
}

.web-ps-urls {
  list-style-type: none;
  padding-left: 0;
  width: 300px;
  li {
    display: block;
    border-bottom: 1px solid $gray-200;
    padding: $spacer/2 0;

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

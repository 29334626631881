$profession-colors: (
        '10-1': #ffffff, /* médecin généraliste */
        '10-2': #ffffff, /* médecin généraliste en formation */
        '80-1': #ffffff, /* pédicure-podologue */
        '40-1': #ffffff, /* chirurgien dentiste */
        '40-2': #ffffff, /* chirurgien dentiste en formation */
        '21-1': #ffffff, /* pharmacien */
        '21-2': #ffffff, /* pharmacien en formation */
        '70-1': #ffffff, /* masseur-kinésithérapeute */
        '60-1': #ffffff, /* infirmier */
        '28-1': $gray-600, /* opticien-lunetier */
        '98-1': $gray-600, /* manipulateur ERM */
        '50-1': $gray-600, /* sage-femme */
        '50-2': $gray-600, /* sage-femme en formation */
        'SECRETARIAT_MEDICAL-1': $gray-600,
        '3': $gray-600,
        'pat-1': $white,
);


$profession-bg-colors: (
        '10-1': #C12941, /* médecin généraliste */
        '10-2': #EA8EA9, /* médecin généraliste en formation */
        '80-1': #FFC000, /* pédicure-podologue */
        '40-1': #422F77, /* chirurgien dentiste */
        '40-2': #B19EC9, /* chirurgien dentiste en formation */
        '21-1': #3B9952, /* pharmacien */
        '21-2': #86C49D, /* pharmacien en formation */
        '70-1': #AB9DCA, /* masseur-kinésithérapeute */
        '60-1': #1D5497, /* infirmier */
        '28-1': #B1CBE6, /* opticien-lunetier */
        '98-1': #8ED1A6, /* manipulateur ERM */
        '50-1': #E484A7, /* sage-femme */
        '50-2': #F8CDDD, /* sage-femme en formation */
        'SECRETARIAT_MEDICAL-1': #F8C997,
        '3': #F8C997,
        'pat-1': $teal,
);

$profession-contents: (
        '10-1': 'MG', /* médecin généraliste */
        '10-2': 'MG', /* médecin généraliste en formation */
        '80-1': 'PD', /* pédicure-podologue */
        '40-1': 'CD', /* chirurgien dentiste */
        '40-2': 'CD', /* chirurgien dentiste en formation */
        '21-1': 'P', /* pharmacien */
        '21-2': 'P', /* pharmacien en formation */
        '70-1': 'MK', /* masseur-kinésithérapeute */
        '60-1': 'I', /* infirmier */
        '28-1': 'OL', /* opticien-lunetier */
        '98-1': 'ERM', /* manipulateur ERM */
        '50-1': 'SF', /* sage-femme */
        '50-2': 'SF', /* sage-femme en formation */
        'SECRETARIAT_MEDICAL-1': 'CPE',
        '3': 'ES',
        'pat-1': 'PAT',
);

.badge-profession {
  font-family: 'Inter';
  font-weight: 800;
  font-size: 9px;
  border-radius: 4px;
  width: auto;
  min-width: 22px;
  height: 15px;
  text-align: center;
  //padding-right: .25rem;
  //padding-left: .25rem;
}

@each $id, $value in $profession-bg-colors {
  .badge-profession-#{$id} {
    background-color: $value;
    color: map-get($profession-colors, $id);

    &:after {
      content: map-get($profession-contents, $id);
    }
  }
}

.dmp-page-container {
  display: flex;
  flex-flow: row;
  flex-grow: 1;

  .dmp-sidebar {
    width: 20%;
    min-width: 300px;
    margin-right: 2rem;
  }

  .dmp-content {
    flex-grow: 1;
  }
}
$mss-message-header-back-button-width: 70px;

.mss-login {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  margin-top: $spacer * 2;
}

.mss-page-container {
  display: flex;
  flex-flow: row;
  flex-grow: 1;
  overflow: hidden;

  .mss-sidebar {
    padding-top: $spacer;
    background-color: $gray-100;
    border-right: 1px solid $gray-200;
    width: 250px;
    min-width: 250px;
    max-width: 250px;
    overflow: auto;

    .mss-sidebar-divider {
      width: calc(80%);
      height: 2px;
      background-color: $gray-200;
      margin: $spacer * 2 auto;
    }
  }

  .mss-content {
    padding: 0;
    flex-grow: 1;
    display: flex;
    flex-flow: column;
    overflow: hidden;

    .mss-actions, .mss-search {
      display: flex;
      flex-flow: row;
      align-items: center;
    }
    .mss-search {
      flex-grow: 1;
      justify-content: flex-end;
    }

    .mss-advanced-search {
      display: flex;
      flex-flow: row;
      gap: $spacer / 2;
      margin-bottom: $spacer / 2;
      flex-wrap: wrap;

      .mss-advanced-search-addresses,
      .mss-advanced-search-dates,
      .mss-advanced-search-content {
        display: flex;
        flex-flow: row;
        gap: $spacer / 2;
      }
      .mss-advanced-search-addresses,
      .mss-advanced-search-dates {
        flex-grow: 1;
        & .input-group {
          max-width: 50%;
        }
      }
      .mss-advanced-search-content {
        flex-grow: 1;

        .mss-advanced-search-content-subject,
        .mss-advanced-search-content-has-attachment {
          max-width: 25%;
          min-width: 25%;
        }
      }
    }

    .mss-messages-table {
      flex-grow: 1;
      display: flex;
      flex-flow: column;
      overflow: hidden;

      .table-responsive {
        flex-grow: 1;
        overflow: auto;
      }
    }

    .mss-message-content {
      display: flex;
      flex-flow: column;

      .mss-message-content-header {
        display: flex;
        flex-flow: row;
        border-bottom: 1px solid $gray-200;

        .mss-message-content-header-back-button {
          width: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 1px solid $gray-200;
        }
        .mss-message-content-header-content {
          flex-grow: 1;
          padding: $spacer;

          .label {
            color: $gray-400;
            width: 30px;
          }

          .mss-message-content-header-content-addresses {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            gap: $spacer;

            .mss-message-content-header-content-addresses-name {
              @extend .typography-default-text-title;
              margin-right: $spacer;
            }
            .mss-message-content-header-content-addresses-address {
              color: $gray-400;
            }
          }
        }
        .mss-message-content-header-extra {
          color: $gray-400;
          padding: $spacer;
          display: flex;
          flex-flow: column;
          align-items: flex-start;
          justify-content: space-between;

          .mss-message-content-header-extra-actions {
            display: flex;
            flex-flow: row;
            width: 100%;
            gap: $spacer / 2;
            justify-content: flex-end;

            button {
              padding: 0;
            }
          }
        }
      }

      .mss-message-content-actions, .mss-message-content-attachments, .mss-message-content-body {
        padding: $spacer $spacer $spacer $mss-message-header-back-button-width;
      }

      .mss-message-content-actions {
        border-bottom: 1px solid $gray-200;
        display: flex;
        flex-flow: row;
        gap: $spacer;
      }

      .mss-message-content-attachments {
        .mss-message-content-attachments-content {
          border: 1px solid $gray-200;
          border-radius: $border-radius;
          padding: $spacer;
          display: flex;
          flex-flow: row;
          width: fit-content;
          gap: $spacer / 2;

          .mss-message-content-attachments-dl-all {
            border-right: 1px solid $gray-200;
            padding-right: $spacer / 2;
          }

          .mss-message-content-attachments-content-attachments {
            display: flex;
            flex-flow: row;
            gap: $spacer / 2;
            flex-wrap: wrap;

            button {
              display: inline-flex;
              justify-content: flex-start;
              align-items: center;
              text-align: left;
              height: auto;

              .size {
                color: $gray-400;
                font-weight: normal;
              }
            }
          }
        }
      }
      .mss-message-content-body {
        blockquote {
          margin-top: 1rem;
        }
        .ck-editor__editable_inline {
          overflow: auto;
          max-height: 400px;
        }
      }
    }
  }
}

.mss-sidebar, .mss-folder-modal {
  .nav {

    .nav-item {
      & > a {
        color: $body-color;
      }

      & > .nav-link {
        padding: $spacer*0.75 $spacer $spacer*0.75 $spacer*2.625;
        border-left: solid 3px transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .badge {
          font-weight: normal;
          background: $gray-100;
          border: 1px solid $gray-200;
          box-sizing: border-box;
          border-radius: 10px;
        }

        &.active {
          @extend .typography-default-text-title;
          color: $primary-700;
          border-left: solid 3px $primary-700;
          background-color: $primary-200;
        }
      }
    }
  }
}

.mss-folder-modal .nav {
  margin-top: 0;

  .nav-item > .nav-link.active {
    border-left: solid 3px transparent;
  }
}
.mss-sidebar .nav {
  margin-top: $spacer * 2;
}


.mss-folder {
  a {
    position: relative;

    .mss-expand-folder {
      position: absolute;

      & i {
        &.ic-chevron-right {
          font-size: 0.75rem;
        }
        &.ic-chevron-down {
          font-size: 0.50rem;
        }
      }
    }
  }
}

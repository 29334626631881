.secondary-nav-bar {
  width: 100%;
  background: $header_background_color;
  padding: 0rem 2rem 0rem 2rem;
  border-bottom: solid 1px $gray-200;
  color: $gray-500;
  font-weight: 500;

  .navbar-nav {
    .nav-link {
      cursor: pointer;
      padding: 1rem 0rem 1rem 0rem;
      margin-right: 33px;

      &.nav-with-pill:after {
        content: '';
        position: absolute;
        top: 15px;
        margin-left: 5px;
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background-color: $orange;
        color: #000000;
        font-size: 14px;
        z-index: 100;
      }

      color: $primary-700;
      border-bottom: transparent solid 2px;

      &.active {
        color: $primary-700;
        border-bottom: $primary-700 solid 2px;
      }

      &:hover {
        border-bottom: $primary-700 solid 2px;
      }
    }
  }
}

.main-nav-bar {
  width: 100%;
  height: $header-height;
  background: $header_background_color;
  padding: 0rem 2rem 0rem 2rem;
  border-bottom: solid 1px $gray-200;

  .logo {
    font-family: Work Sans, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
  }

  .navbar-nav {
    height: 100%;

    .nav-item {
      width: 163px;
      color: $gray-500;
      font-weight: 500;
      font-size: 0.75rem;
      display: flex;
      justify-content: center;
      align-items: center;

      &.active {
        background-color: $primary-700;
        color: white;
      }

      &:not(.no-hover):hover {
        background-color: $primary-200;
        color: $primary-700;
      }
    }
  }

  .navbar-brand {
    img {
      width: 130px;
      height: 22.6px;
    }
  }

}

.nav-user {
  display: flex;
  align-items: center;
  .nav-user-identity-facility{
    font-size: 0.75rem;
    font-weight: 400;
  }
  .nav-user-identity-name {
    font-weight: 500;
  }
  .nav-user-identity {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

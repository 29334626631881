.tgl {
  display: none;

  &,
  &:after,
  &:before,
  & *,
  & *:after,
  & *:before,
  & + .tgl-btn {
    box-sizing: border-box;

    &::selection {
      background: none;
    }
  }

  + .tgl-btn {
    outline: 0;
    display: inline-block;
    width: 24px;
    height: 12px;
    position: relative;
    cursor: pointer;
    user-select: none;

    &:after,
    &:before {
      position: relative;
      display: block;
      content: "";
      width: 50%;
      height: 100%;
    }

    &:after {
      left: 0;
    }

    &:before {
      display: none;
    }
  }

  &:checked + .tgl-btn:after {
    left: 50%;
    background-color: $primary-700;
  }
}

// themes

.tgl {
  + .tgl-btn {
    background: $primary-200;
    border-radius: 2em;
    padding: 1px;
    transition: all .4s ease;

    &:after {
      border-radius: 50%;
      background: $gray-400;
      transition: all .2s ease;
    }
  }

  &:checked + .tgl-btn {
    background: $primary-200;
  }

  &[disabled] {
    + .tgl-btn {
      background: #f0f6ff;
      border-radius: 2em;
      padding: 2px;
      transition: all .4s ease;

      &:hover {
        cursor: not-allowed;
      }
    }
  }
}

.tgl-label {
  padding-left: .5em;
  cursor: pointer;
}


$theme-colors: map-merge(
  (
  "sand":    $sand,
  "no-access":    $danger,
  ),
  $theme-colors
);


.bg-sand-100{
  background-color: $sand-100;
}

.bg-sand-200{
  background-color: $sand-200;
}

.bg-sand-300{
  background-color: $sand-300;
}

.bg-sand-500{
  background-color: $sand-500;
}

.bg-sand-700{
  background-color: $sand-700;
}

.bg-primary-100{
  background-color: $primary-100;
}

.bg-primary-200{
  background-color: $primary-200;
}

.bg-primary-300{
  background-color: $primary-300;
}

.bg-primary-400{
  background-color: $primary-400;
}

.bg-primary-500{
  background-color: $primary-500;
}

.bg-primary-600{
  background-color: $primary-600;
}

.bg-primary-700{
  background-color: $primary-700;
}

.bg-primary-800{
  background-color: $primary-800;
}

.bg-gray-100{
  background-color: $gray-100;
}

.bg-gray-200{
  background-color: $gray-200;
}

.bg-gray-300{
  background-color: $gray-300;
}

.bg-gray-400{
  background-color: $gray-400;
}

.bg-gray-500{
  background-color: $gray-500;
}

.bg-gray-600{
  background-color: $gray-600;
}

.bg-gray-700{
  background-color: $gray-700;
}

.bg-gray-800{
  background-color: $gray-800;
}


.text-normal {
  color: $body-color !important;
}
.text-gray-300 {
  color: $gray-300 !important;
}
.text-gray-400 {
  color: $gray-400 !important;
}
.text-gray-500 {
  color: $gray-500 !important;
}
.text-sand-700 {
  color: $sand-700 !important;
}
.text-primary-500 {
  color: $primary-500 !important;
}



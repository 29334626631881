/*-----------------------------------------------------------------------
APPLICATION LEVEL LAYOUT
-----------------------------------------------------------------------*/
body {
  background-color: $sand-100;
}

.container {
  overflow: hidden;
  position: relative;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.12);
  background-color: white;
  &.no-shadow {
    box-shadow: none;
  }
}

#header {

}

#main {
  display: flex;
  flex-flow: row;
  flex-grow: 1;
  overflow: hidden;

  #page {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    overflow: auto;

    #page-header {

    }

    #page-content {
      display: flex;
      flex-flow: column;
      flex-grow: 1;
      overflow: auto;

      padding: 1.5rem 2rem;
    }
  }

  #sidebar {

  }
}

.container.admin #page-content {
  padding: 0 !important;
}

#footer {
  color: $gray-400;
  min-height: 30px;
  font-size: 0.625rem;
  background: $white;
  border-top: 1px solid $gray-200;
  display: flex;
  justify-content: center;
  align-items: center;

  a, button {
    color: $gray-400;
    font-weight: 500;
  }
}

.full-screen-dropzone {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  overflow: hidden;
}


@media (min-width: 1900px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1900px!important;
  }
}

@media (max-width: 1900px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 100%!important;
  }
}

.container{
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  width: 100%;
  margin-right: 2rem;
  margin-left: 2rem;
  padding-right: -15px;
  padding-left: -15px;
}

.centered-container {
  height: 100%;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.documentContent {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
}

.documentContentHeader {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.documentContentBody {
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  overflow-x: auto;
  overflow-y: hidden;
}

.documentContentData {
  flex-grow: 1;

  .txt {
    white-space: pre-wrap;
  }
  .pdf {
    height: 100%;
  }
}

.top, .centered {
  display: flex;
  flex-flow: column;
}

.h-centered > * {
  margin-right: auto;
  margin-left: auto;
}

.v-centered > * {
  margin-top: auto;
  margin-bottom: auto;
}


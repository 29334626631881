.badge-approved {
  background-color: $success-100;
  color: $success;
  border-radius: 10px;
  padding: $spacer/4 $spacer/2;
}
.badge-archive {
  color: #B57803;
  background-color: #FCEDCF;
  border-radius: 10px;
  padding: $spacer/4 $spacer/2;
}
.badge-obsolete {
  color: #900606;
  background-color: #F5D8D8;
  border-radius: 10px;
  padding: $spacer/4 $spacer/2;
}

.badge {
  display: inline-flex;
  align-items: center;
  padding: $spacer/3;
  i {
    margin-right: $spacer / 2;
  }
}

.drop-zone {
  width: 100%;
  height: 100%;
  text-align: center;
  border: 2px dashed $primary;
  color: $primary;
  background: $body-bg;
  position: relative;

  & .icon {
    font-size: 2rem;
  }
}

.drop-zone:hover {
  cursor: pointer;
}

body{
  line-height: 130%;
}

.typography-subtitle {
  font-weight: 400;
  font-size: $font-size-base * 1.33; /* 16px*/
  line-height: 130%;
}

.typography-big-text-title {
  font-weight: 500;
  font-size: $font-size-base * 1.17; /* 14px*/
  line-height: 130%;
}

.typography-big-text {
  font-weight: 400;
  font-size: $font-size-base * 1.17; /* 12px*/
  line-height: 130%;
}

.typography-default-text-title {
  font-weight: 500;
  font-size: $font-size-base; /* 12px*/
  line-height: 130%;
}

.typography-default-text{
  font-weight: 400;
  font-size: $font-size-base; /* 12px*/
  line-height: 130%;
}

.typography-small-link{
  font-weight: 400;
  font-size: $font-size-base * 0.833; /* 10px*/
  line-height: 120%;
}

.typography-defaut-text{
  font-weight: 500;
  font-size: $font-size-base * 0.833; /* 10px*/
  line-height: 120%;
}

.typography-small-italic{
  font-weight: 400;
  font-style: italic;
  font-size: $font-size-base * 0.833; /* 10px*/
  line-height: 120%;
}
.card-mini {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  margin-bottom: 1rem;

  .card-footer{
    background-color: $primary-100;
    padding: 10px 12px 10px 12px;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5rem;

    .btn-link {
      padding: 0;
    }
  }
  .card-body {
    padding: 10px 12px 10px 12px;
  }
}

.card-mini-subtitle{
  color: $gray-400;
  font-weight: 400;
  margin-top:8.5px ;
}

.card-mini-title {
  word-break: break-word;

  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  & > i {
    margin-right: 10px;
  }

  &.news.is-new:before {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: $primary-500;
    content: '';
    margin-right: $spacer / 3;
  }
}

.card-mini-action-title {

}

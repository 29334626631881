.ps-name-hover {
  cursor: pointer;


  &:hover {
    text-decoration: underline;
  }
}

.popover {
  border: $border-width solid $gray-200;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  max-width: 300px;
  z-index: 10000;

  &.ps {
    min-width: 250px;
  }

  .popover-header {
    border: none;
    background-color: $white;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > i {
      margin-right: 10px;
    }
  }

  .popover-body {
    background-color: $white;
  }

  .popover-footer {
    background-color: $primary-100;
    padding: 10px 12px 10px 12px;
  }

  .arrow {
    display: none;
  }
}


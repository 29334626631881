.mss-content {
  .mss-refresh-button {
    .spinner-border {
      width: 20px;
      height: 20px;
    }
  }

  .mss-messages-table {
    tbody {
      tr {
        &.unread {
          .td-from, .td-subject {
            @extend .typography-default-text-title;
          }
        }
      }
    }

    .fragment {
      color: $gray-400 !important;
      font-weight: normal !important;
    }
  }
}

.btn-group-toggle {
  flex-wrap: wrap;
  gap: $spacer / 4;

  & > .btn {
    display: inline-flex;
    flex-grow: 0;
    align-items: center;
    border-radius: $spacer / 4 !important;
    margin: 0 $spacer / 8 !important;

    &:first-child {
      margin-left: 0;
    }

    &:not(:first-child) {
      border-left-width: $border-width !important;
    }

    &:last-child {
      margin-right: 0;
    }

    //input[type="checkbox"] {
    //  position: relative;
    //  margin-right: $spacer / 2;
    //  width: 15px;
    //  height: 15px;
    //  border-radius: 4px;
    //  border: #A4B0C0 solid 1px;
    //  background-color: $primary-700;
    //}
  }


  &.btn-group-vertical {
    width: 100%;

    & > .btn {
      display: flex;
      justify-content: flex-start;
    }
  }

  .toggle-button {
    background-color: $white !important;
    border: $border-width solid $gray-200 !important;
    color: $gray-600 !important;


    &:hover, &:active, &.active {
      border-color: $primary-700 !important;
      color: $primary-700 !important;

      & i:before {
        color: inherit;
      }
    }

    &.active, &:active {
      background-color: $primary-200 !important;
    }

    &:hover {
      background-color: $white !important;
    }
  }
}

.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) {
  margin-left: 0;
}

.toggle-checkbox-container {
  display: flex;
  flex-wrap: wrap;
  gap: $spacer / 4;

  &.vertical {
    width: 100%;
    flex-flow: column;
  }

  .toggle-checkbox {
    padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
    border: $border-width solid transparent;
    border-radius: $border-radius;
    display: flex;
    flex-flow: row;
    align-items: center;

    .toggle-checkbox-label {
      flex-grow: 1;
    }

    .custom-control {

      input:disabled ~ .custom-control-label {
        opacity: .5;
      }

      .custom-control-label {
        width: 100%;
      }
    }

    &.active, &.borders {
      //padding: $btn-padding-x-sm $btn-padding-y-sm;
      border-color: $gray-200;
    }
  }

  //&.width-auto {
  //  width: auto;
  //  .toggle-checkbox .custom-control {
  //    display: inline-flex;
  //    width: auto;
  //    .custom-control-label  {
  //      width: auto;
  //    }
  //  }
  //}
}

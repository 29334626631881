.container.document-container {
  background-color: $sand-100;
  box-shadow: none;
  //position: relative;

  .secondary-header {
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.12);
  }

  .document-page-container {
    display: flex;
    flex-flow: row;
    flex-grow: 1;
  }

  .document-sidebar {
    //position: sticky;
    //top: 0px;
    //z-index: 1040;
    width: 20%;
    min-width: 300px;
    margin-right: 2rem;
    display: flex;
    flex-flow: column;

    hr {
      width: 100%;
      color: $sand-300;
    }

    .info hr {
      color: $gray-200;
    }

    .visibility {
      color: $red;
      & > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: $spacer / 3;

        i:before {
          color: inherit;
          font-size: 0.8rem;
        }
      }
    }
  }

  .document-content {
    flex-grow: 1;
    display: flex;
    flex-flow: column;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.12);
    background-color: $white;
    border-radius: 4px 4px 0px 0px;
    padding: 20px;
    overflow: auto;
  }
}

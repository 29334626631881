$primary-100: #F8FAFC;
$primary-200: #E8F0FB;
$primary-300: #BFD4F2;
$primary-400: #7FA7E4;
$primary-500: #3080F8;
$primary-600: #296DD2;
$primary-700: #1F4D93;
$primary-800: #0F3369;


$green: #089171;
$orange: #F28118;
$blue: $primary-700;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #f53282;
$red: #CE3E3E;
$yellow: #FFCE67;
$teal: #20c997;
$cyan: #399AF2;

$white: #fff;
$gray-50: #f0f6ff;
$gray-100: #F6F9FD;
$gray-200: #DEE5EF;
$gray-300: #A4B0C0;
$gray-400: #73839C;
$gray-500: #394A64;
$gray-600: #293854;
$gray-700: #192B46;
$gray-800: #112138;
$gray-900: #080F1A;
$black: #000;

$sand-100: #FAF4F0;
$sand-200: #F8ECE4;
$sand-300: #DED2CC;
$sand-500: #A69187;
$sand-600: #93776A;
$sand-700: #6C5346;

$sand: $sand-500;
$primary: $blue;
$secondary: $primary-200;
$success: $green;
$success-100: #E3F7F3;
$info: $cyan;
$warning: #F28118;
$danger: $red;
$light: $gray-50;
$dark: #15182b;

$color-secondary-state: $secondary;
$color-success-state: $success;
$color-danger-state: $danger;
$color-warning-state: $warning;
$color-info-state: $info;


$body-bg: $primary-200;
$body-color: $gray-800;

$header_background_color: $white;
$header_emergency_background_color: red;
$header_emergency_color: $white;
$header_menu_patient_color: $pink;

$table-border-color: $gray-50;

.vertical-timeline {
  display: flex;
  flex-flow: column;
  gap: $spacer;
  position: relative;
  margin-bottom: $spacer;

  & > * {
    transform: translateZ(0);
  }

  &::before {
    background-color: $sand-300;
    content: '';
    position: absolute;
    top: 0;
    left: $spacer / 2;
    height: 100%;
    width: 1px;
  }

  .vertical-timeline-day {

    .vertical-timeline-marker {
      position: relative;
      @extend .typography-default-text-title;
      border-bottom: 2px solid $primary-700;
      color: $primary-700;
      flex-grow: 1;
      padding-left: $spacer * 1.5;
      padding-bottom: $spacer * .5;
      margin-left: 1px;
      margin-top: $spacer;

      &:before {
        position: absolute;
        bottom: -0.1px;
        left: 0;
        content: "";
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;

        border-bottom: 8px solid $primary-700;
      }
    }

    .vertical-timeline-element {
      position: relative;
      display: flex;
      flex-flow: row;
      align-items: center;

      .vertical-timeline-element-dot {
        position: absolute;
        top: calc(50% - 3px);
        left: calc(0.5rem - 3px);
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: $sand-300;
      }

      .vertical-timeline-element-date {
        margin-left: $spacer * 2;
        width: $spacer * 4;
        color: $sand-500;
      }

      &:first-of-type .vertical-timeline-element-content {
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
      }

      &:last-of-type .vertical-timeline-element-content, .vertical-timeline-element-content.last  {
        border-bottom: $border-width * 2 solid $gray-200;
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }

      .vertical-timeline-element-content {
        border-top: $border-width * 2 solid $gray-200;
        border-right: $border-width * 2 solid $gray-200;
        border-left: $border-width * 2 solid $gray-200;
        padding: $spacer;
        flex-grow: 1;
        display: flex;
        flex-flow: row;
        align-items: center;
        gap: $spacer;

        .vertical-timeline-element-content-icon {
          display: flex;
          align-items: center;
          justify-items: center;

          & > i {
            border-radius: $border-radius;
            background-color: $sand-200;
            height: 24px;
            width: 24px;
            &:before {
              margin: auto;
              line-height: 24px;
              color: $sand-600;
            }
          }
        }

        .vertical-timeline-element-content-title {
          display: flex;
          flex-flow: column;
          flex-grow: 1;
        }

        .vertical-timeline-element-content-event-source {
          margin-left: auto;
        }
      }
    }
  }

  .vertical-timeline-month-header {
    border: $border-width * 2 solid $sand-100;
    border-radius: $border-radius;
    background-color: $sand-100;
    padding: $spacer / 4 $spacer;
    color: $sand-700;
    font-weight: 600;
  }
}

.timeline-filters-title {
  color: $gray-400;
}

.secondary-header{

  font-weight: 700;
  height:89px;
  background: $header_background_color;
  padding: 0rem 2rem 0rem 0rem;
  border-bottom: solid 1px $gray-200;

  .navbar-back-button {
    background: $header_background_color;
  }

  &>div{
    display: flex;
    flex-direction: column;
  }
  &.secondary-header-small{
    height:56px;
    .secondary-header-title{
      padding-top: 0px;
    }
    .secondary-header-actions{
      padding-top: 0px;
    }
  }
  .secondary-header-left-section{
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .secondary-header-right-section{
    height: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    .secondary-nav-bar{
      padding: 0rem 0.5rem 0rem 2rem;
    }
    .nav-link {
      cursor: pointer;
      margin-left: 33px;
      margin-right: 0px;
      text-align: right;
    }
  }
  .navbar{
    margin-top: auto;
    position:relative;
    top:1px;
    .nav-link {
      cursor: pointer;
      padding: 0rem 0rem 1rem 0rem;

      &:disabled, &.disabled {
        color: $gray-300;
      }
    }
  }

  .navbar-back-button{
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: solid 1px $gray-200;
    &>.navbar-back-button-label{
      color: $gray-400;
      font-weight: 500;
      margin-left: 16px;
      margin-top:4px;
    }
    &.navbar-back-button-with-label{
      width: 200px;
    }
    &:hover{
      background-color: $gray-100;
    }
  }
  .secondary-header-title{
    padding-top: 15px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    font-size: 1rem;
    &>i{
      margin-right:10px
    }
  }
  .secondary-header-actions {
    padding-top: 9px;
    display: flex;
    justify-content: flex-end;
    .btn {
      margin-right: 8px;
    }
    .dropdown .dropdown-toggle {
      margin-right: 0;
    }
  }

  &.emergency, &.emergency .secondary-nav-bar {
    background: #F5D8D8;
    border-color: #CE3E3E;
  }
  &.emergency .navbar-back-button {
    border-right-color: #CE3E3E;
  }
}

.secondary-header .secondary-header-right-section .main-nav-bar {
  width: initial;
  margin-top: initial;
  position: initial;
  border-top: solid 1px #DEE5EF;
  height: 57px;
  .nav-link{
    padding: 1rem 0rem 1rem 0rem;
    margin-left: 0px;
    margin-right: 0px;
  }
}


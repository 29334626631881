.menu-dmp-categories {
  padding: 0 0 1rem 0;

  .category-label {
    flex-grow: 1;
    display: flex;
    flex-flow: row;
    align-items: center;

    .label {
      flex-grow: 1;
    }
  }
  & .nav > li.nav-dropdown {

    & .has-arrow::after {
      transform: rotate(135deg) translate(0, -50%);
    }

    &.active .has-arrow::after {
      transform: rotate(225deg) translateY(-50%);
    }

    & > div, & > .nav-sub > li {
      padding-right: 0.65rem;
      min-height: calc(2rem + #{$spacer} / 4);
      margin-bottom: $spacer / 4;
      border-radius: $border-radius;
      display: flex;
      flex-flow: row;
      align-items: center;
      border: $border-width solid transparent;

      &.checked {
        border-color: $gray-200;
      }

      .arrow-button {
        i {
          justify-content: center;
          width: 16px;

          &.ic-chevron-down {
            font-size: 10px;

            :before {
              color: $primary-700;
            }
          }
          &.ic-chevron-right {
            font-size: 14px;

            :before {
              color: $gray-300;
            }
          }
        }
      }

      & span.badge {
        background-color: $gray-100;
        border: $border-width solid $gray-200;
        border-radius: 10px;
        margin-left: $spacer / 3;
        font-weight: 400;

        &.empty {
          background-color: $white;
          border: $border-width solid $gray-200;
          color: $gray-300;
        }

        &.new {
          background-color: $primary-500;
          border: $border-width solid $primary-500;
          color: $white;
          font-weight: 700;
        }
      }
    }

    .nav-sub {
      padding-left: 2rem;
      li {
        padding-left: 0.65rem;
      }
    }
  }
}

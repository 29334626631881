.rbt {
  box-shadow: none !important;

  .rbt-menu {
    width: auto !important;

    &.dropdown-menu {
      .dropdown-item {
        display: block;
      }
    }
  }
}

.rbt-input {
  &.focus {
    box-shadow: none !important;
    background-color: $white;
    border-color: $primary;
  }
}

.rbt-input-multi.form-control {
  @extend .form-control-sm;
  height: auto !important;

  &[disabled] {
    background-color: $white;

    .rbt-token {
      background-color: $secondary;
      color: $white;
    }
  }
}

.rbt-token, .rbt-input-multi.form-control[disabled] .rbt-token {
  background-color: $primary;
  color: $white;

  .rbt-token-remove-button {
    color: $white !important;
  }
}

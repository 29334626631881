.ReactTable {
  flex: 1;
}

/**
FIX double scroll bar
 */
.ReactTable .rt-tbody {
  overflow: hidden !important;
}

.ReactTable .rt-th, .ReactTable .rt-td {
  padding: 3px 2px;
  font-size: 12px;
}

.ReactTable .rt-tbody .rt-td {
  align-self: center;
}

.ReactTable .-pagination {
  font-size: 0.9em;
}

.ReactTable .rt-tr-group {
  cursor: pointer;
}
.ReactTable .rt-tbody .rt-tr:hover {
  background: rgba(0,0,0,0.05) !important;
}

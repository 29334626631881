.width-auto {
  width: fit-content !important;
}

.tr-vitale-card-patient {
  cursor: pointer;
}

.tr-vitale-card-patient td {
  vertical-align: middle;
}

.dmp-status-no-ins {
  width: 33px;
  margin: 3.5px 4px 3.5px 0;
}

.pointer {
  cursor: pointer;
}

.bg-danger > button.close,
.bg-warning > button.close {
  color: white !important;
}

.table-hover tbody tr.active {
  color: $table-hover-color;
  background-color: $table-hover-bg;
}

@keyframes fadeIn {
  from { opacity: 0; }
}
.detection {
  margin-left: $spacer * 1.5;
  font-size: 24pt;
  animation: fadeIn 1s infinite alternate;
}
.loginLogo {
  width: 200px;
  margin: 0 auto;
  display: block;
}
#pdfViewer {
  flex: 1;
}

table.dmp-statistics {
  width: 100%;
  tr {
    border: none !important;
    td {
      border: none !important;
      padding: 0;
      white-space: nowrap;

      &:last-child {
        width: 100%;
      }
      &.td-stat {
        text-align: right;
        padding-right: 0.5em;
      }
      &.td-separator {
        padding: 0 .5em;
      }
    }
  }
}

.officine-tag, .pui-tag {
  width: 10px;
  height: 10px;
  border-radius: 50%
}

.officine-tag {
  background: green;
}
.pui-tag {
 background: blue;
}

.dmp-status-actions {
  & > * {
    margin-right: $spacer / 2;
    &:last-child {
      margin-right: 0;
    }
  }
}

.beta-badge {
  width: fit-content;
  background-color: $primary-500 !important;
}
.beta-old-link {
  display: block;
  padding: $spacer;
}

.dp-es-type-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $primary-200;
  border: 1px solid transparent;
  border-radius: $border-radius;
  padding: 0.5rem 1rem;
  cursor: pointer;

  i {
    justify-content: center;
    align-items: center;

    &:before {
      color: $gray-800;
    }

    &.ic-chevron-down {
      font-size: 10px;
    }

    &.ic-chevron-left {
      font-size: 14px;
    }
  }
}

.pagination .page-item.disabled {
  opacity: .5;
}

$btn-disabled-opacity: .35;
$btn-font-weight: 500;
$border-radius-lg: .25rem;
$border-radius-sm: .25rem;
$border-radius: .25rem;
$btn-font-size-sm: $font-size-base; /* 12px */
$btn-font-size: $font-size-base * 1.17; /* 14px */
$btn-font-size-lg: $font-size-base * 1.33; /* 16px */

$btn-padding-y-lg: .875rem;
$btn-padding-x-lg: 1.3125rem;
$btn-line-height-lg: 130%;

$btn-line-height: 130%;
$btn-padding-y: .625rem;
$btn-padding-x: 1rem;

$btn-padding-y-sm: .375rem;
$btn-padding-x-sm: .75rem;
$btn-line-height-sm: 130%;


$btn-active-box-shadow: none;
$btn-focus-box-shadow: none;

$btn-active-background-primary-color: $primary-800;

$btn-active-background-colors: (
        primary: $primary-800,
        secondary: $primary-300,
        warning: #F28118,
        danger: #900606,
        success: #075B48,
        sand: $sand-200,
);

$btn-colors: (
        primary: $white,
        secondary: $primary-700,
        success: $white,
        danger: $white,
        sand: $gray-600,
);

$btn-active-colors: (
        primary: $white,
        secondary: $primary-700,
        success: $white,
        danger: $white,
        sand: $gray-600,
);

$btn-borderless-colors: (
        primary: $primary-700,
        success: #075B48,
        danger: #BD0D0D,
);

$btn-active-borderless-background-colors: (
        primary: $primary-200,
        success: #E3F7F3,
        danger: #F5D8D8,
);

$btn-active-borderless-colors: (
        primary: $primary-700,
        success: #075B48,
        danger: #900606,
);

$btn-outline-colors: (
        primary: $primary-700,
        secondary: $gray-600,
        success: #089171,
        danger: #CE3E3E,
        warning: $gray-600,
        no-access: $gray-600,
);

$btn-outline-border-colors: (
        primary: $primary-700,
        secondary: $gray-200,
        success: #089171,
        danger: #CE3E3E,
        no-access: #E28B8B,
        warning: $warning,
);

$btn-active-outline-colors: (
        primary: $primary-700,
        secondary: $gray-600,
        success: #089171,
        danger: #900606,
        warning: $gray-600,
        no-access: $gray-600,
);

$btn-outline-background-colors: (
        primary: $white,
        secondary: $white,
        success: $white,
        danger: $white,
        no-access: $white,
        warning: $white,
);

$btn-active-outline-background-colors: (
        primary: $primary-200,
        secondary: $gray-100,
        success: #E3F7F3,
        danger: #F5D8D8,
        no-access: $sand-200,
        warning: $sand-200,
);

$btn-border-colors: (
        primary: transparent,
        secondary: $gray-200,
        warning: transparent,
        danger: transparent,
        success: transparent,
        sand: $gray-200,
);

$btn-active-border-colors: (
        primary: transparent,
        secondary: $primary-300,
        warning: transparent,
        danger: transparent,
        success: transparent,
);




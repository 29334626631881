.dropdown-item{
  display: flex;
  align-items: center;
  &:disabled, &.disabled {
    opacity: 0.35;
  }
}

.has-arrow {
  position: relative;

  &::after {
    position: absolute;
    content: '';
    width: 0.5em;
    height: 0.5em;
    border-width: 1.5px 0 0 1.5px;
    border-style: solid;
    border-color: #607d8b;
    right: 1em;
    transform: rotate(-45deg) translate(0, -50%);
    transform-origin: top;
    top: 50%;
    transition: all 0.3s ease-out;

  }

  &.on-border::after {
    right: 0;
  }

  .active > &::after,
  &[aria-expanded="true"]::after,
  &.active::after {
    -webkit-transform: rotate(-135deg) translate(0, -50%);
    -ms-transform: rotate(-135deg) translate(0, -50%);
    -o-transform: rotate(-135deg) translate(0, -50%);
    transform: rotate(-135deg) translate(0, -50%);
  }

  &.before {
    &::after {
      right: auto;
      left: 0;
      transform: rotate(135deg) translate(0, -50%);
    }

    &.active::after {
      transform: rotate(225deg) translate(0, -50%);
    }
  }
}

.dropdown-menu{
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  padding: 10px 7px;

  .dropdown-header {
    font-size: $h6-font-size;
    font-weight: 700;
  }

  & > div:not(.dropdown-divider) {
    width: 100%;
  }

  .dropdown-item {
    padding: 7px 10px;
    width: 100%;
    &:hover{
      background-color: $primary-200;
      border-radius: 4px;
      color: $gray-500;
    }
  }

}

.dropdown-divider {
  height: 0;
  margin: 7px 10px;
}

.dropdown.no-arrow {
  .dropdown-toggle{
    @extend .btn-sm;

    &:after {
      content: none;
    }
  }
}


.table {

  thead th {
    &:first-of-type {
      padding-left: 1rem;
    }

    padding-top: .75rem;
    padding-bottom: .75rem;
    border-bottom: none;
    border-top: none;
    display: table-cell !important;

    &.th-filter {
      padding-top: .375rem;
      padding-bottom: .375rem;
    }

    &.bottom {
      border-bottom: $table-border-width solid $table-border-color;
    }

    &.sorted {
      color: $primary-600;
      border-bottom: $table-border-width solid $primary-600;

      & i {
        margin-left: .35rem;
        font-size: 0.75rem;
        width: 0.75rem;
        height: 0.75rem;
        &:before {
          color: $primary-600;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        height: 2.8rem;
        border-top: $table-border-width solid $table-border-color;
        border-bottom: $table-border-width solid $table-border-color;
        vertical-align: middle;

        &:first-of-type {
          padding-left: 1rem;
        }
      }

      &:not(:last-of-type) td {
        border-bottom: none;
      }
    }
  }
}

.table-extra-header {
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: $spacer;
  margin-bottom: $spacer;

  .table-extra-header-selection {
    padding-left: $spacer;
  }

  .table-extra-header-content {
    flex-grow: 1;
  }
  .table-extra-header-pager {
    .btn i {
      font-size: 1rem;
    }
  }

  .table-extra-sub-header {

  }
}

.sidebar-menu{
  margin-top: 10px;
  background-color: $sand-100;
  border: 1px solid #DED2CC;
  border-radius: 12px 0px 0px 0px;
  height: calc(100% - 10px);
  display: flex;
  flex-flow: column;
  overflow: hidden;

  &.sidebar-menu-opened{
    width: 300px;

    .sidebar-menu-content {
      padding: 30px;
    }
  }
  &.sidebar-menu-closed{
    width: 50px;
    cursor: pointer;
  }

  .sidebar-menu-content {
    flex-grow: 1;
    overflow-y: auto;

    .sidebar-menu-title{
      color: $sand-700;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }

  .sidebar-menu-footer{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0.5rem 0.5rem 0.5rem 0;

    .sidebar-menu-btn{
      &.sidebar-menu-btn-open{
        background-image: url("data:image/svg+xml,%3Csvg width='30' height='31' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='29.5' y='29.9999' width='29' height='29' rx='3.5' transform='rotate(-180 29.5 29.9999)' fill='white'/%3E%3Cpath d='M17.0002 20.8332L17.9402 19.8932L14.2202 16.1665L22.3335 16.1665L22.3335 14.8332L14.2202 14.8332L17.9468 11.1132L17.0002 10.1665L11.6668 15.4998L17.0002 20.8332Z' fill='%2373839C'/%3E%3Cline x1='8.25' y1='22.4999' x2='8.25' y2='8.49988' stroke='%2373839C' stroke-width='1.5'/%3E%3Crect x='29.5' y='29.9999' width='29' height='29' rx='3.5' transform='rotate(-180 29.5 29.9999)' stroke='%23DEE5EF'/%3E%3C/svg%3E%0A");
        height: 30px;
        width: 30px;
      }
      &.sidebar-menu-btn-close{
        background-image: url("data:image/svg+xml,%3Csvg width='30' height='31' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.999878' width='29' height='29' rx='3.5' fill='white'/%3E%3Cpath d='M12.9998 10.1666L12.0598 11.1066L15.7798 14.8333L7.6665 14.8333L7.6665 16.1666L15.7798 16.1666L12.0532 19.8866L12.9998 20.8333L18.3332 15.4999L12.9998 10.1666Z' fill='%2373839C'/%3E%3Cline x1='21.75' y1='8.49988' x2='21.75' y2='22.4999' stroke='%2373839C' stroke-width='1.5'/%3E%3Crect x='0.5' y='0.999878' width='29' height='29' rx='3.5' stroke='%23DEE5EF'/%3E%3C/svg%3E%0A");
        height: 30px;
        width: 30px;
      }
    }
  }
}

.nav.tab-container.nav-pills .nav-link {
  transition: all .15s ease;
  color:      $secondary;
  padding:    .75rem 1.5rem;

  & i {
    color:        $secondary;
    margin-right: .5rem;
  }

  &:hover,
  &.active,
  &:active {
    color: $primary;

    & i {
      color: $primary;
    }
  }
}

.tab-pane > h1,
.tab-pane > h2,
.tab-pane > h3,
.tab-pane > h4,
.tab-pane > h5,
.tab-pane > h6 {
  padding-bottom: $spacer * 1.5;
}


.mss-recipients {
  width: 100%;

  hr {
    width: 100%;
    margin-bottom: 0;
    margin-top: $spacer / 2;
    color: $gray-200;
  }

  .mss-recipient-name {
    display: flex;
    width: 100%;
  }
  .mss-recipient-email {
    color: $gray-400;
  }
}

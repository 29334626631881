$notification-width: 350px;

.notification{
  &:not(:last-child) {
    margin-bottom: .75rem;
  }
  width: $notification-width;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding-bottom: 16px;
  min-height: 86px;
  & >.notification-container {
    display: flex;
    &> .notification-content{
      width: 258px;
      padding-top: 4px;
    }
    & > .notification-icon{
      width:48px;
      display: flex;
      justify-content: center;
    }

  }
  & > .notification-header{
    padding-top: 16px;
    padding-left: 48px;
    padding-right: 44px;
    font-weight: 500;
    font-size: 14px;
  }

  & > .notification-border{
    border-radius: 4px 4px 0px 0px;
    height: 4px;
    width: $notification-width;
    position: absolute;
  }
  &.notification-info{
    & > .notification-border{
      background-color: $primary-700;
    }
  }
  &.notification-danger{
    & > .notification-border{
      background-color: $danger;
    }
  }
  &.notification-warning{
    & > .notification-border{
      background-color: #B57803;
    }
  }
  &.notification-success{
    & > .notification-border{
      background-color: $success;
    }
  }
}

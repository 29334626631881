.form-group.invalid .ck.ck-editor {
  //.ck.ck-toolbar {
  //  border-top-color: $danger;
  //  border-left-color: $danger;
  //  border-right-color: $danger;
  //}

  .ck.ck-editor__main>.ck-editor__editable {
    border-color: $danger;
  }
}

.ck.ck-content {
  min-height: 250px;
}

.alert-warning, .btn-warning {
  color: theme-color-level('warning', 7);
}
.alert-info {
  color: $primary-700;
  background-color: $primary-200;
}

.alert-danger {
  color: #900606;
  background-color: #F5D8D8;
}

.alert-warning {
  color: #B57803;
  background-color: #FCEDCF;
}

.alert-success {
  color: #089171;
  background-color: #E3F7F3;
}

.alert{
  border: none;
  & > .alert-icon {
    padding: 0 1rem;
  }
  & > i{
    margin-right: 1rem;
  }
  & > .alert-content{
    flex-grow: 1;
    margin-top: 3px;
  }
}

.alert-icon{
  height: 20px;
  width: 20px;
  display: inline-block;
  background-repeat: no-repeat;
  &.alert-icon-info {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.3332 9.99996C18.3332 14.602 14.6019 18.3333 9.99984 18.3333C5.39775 18.3333 1.6665 14.602 1.6665 9.99996C1.6665 5.39788 5.39775 1.66663 9.99984 1.66663C14.6019 1.66663 18.3332 5.39788 18.3332 9.99996Z' fill='%23BFD4F2'/%3E%3Cpath d='M9.16683 9.16671H10.8335V13.75H9.16683V9.16671ZM11.0418 6.87504C11.0418 7.44962 10.5747 7.91671 10.0002 7.91671C9.42558 7.91671 8.9585 7.44962 8.9585 6.87504C8.9585 6.30046 9.42558 5.83337 10.0002 5.83337C10.5747 5.83337 11.0418 6.30046 11.0418 6.87504Z' fill='%231F4D93'/%3E%3C/svg%3E%0A");
  }
  &.alert-icon-danger {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.3332 10.0001C18.3332 14.6022 14.6019 18.3334 9.99984 18.3334C5.39775 18.3334 1.6665 14.6022 1.6665 10.0001C1.6665 5.398 5.39775 1.66675 9.99984 1.66675C14.6019 1.66675 18.3332 5.398 18.3332 10.0001Z' fill='%23EBB2B2'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.5348 7.64342L12.3565 6.46509L9.99984 8.82175L7.64318 6.46509L6.46484 7.64342L8.82151 10.0001L6.46484 12.3568L7.64318 13.5351L9.99984 11.1784L12.3565 13.5351L13.5348 12.3568L11.1782 10.0001L13.5348 7.64342Z' fill='%23900606'/%3E%3C/svg%3E%0A");
  }
  &.alert-icon-warning {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.4136 17.8179H2.59575C2.26374 17.8179 1.95812 17.6405 1.79281 17.353C1.62796 17.0654 1.62889 16.7116 1.79605 16.425L9.20499 3.45935C9.37076 3.17504 9.67546 3 10.0047 3C10.3339 3 10.6386 3.17504 10.8049 3.45935L18.2138 16.425C18.381 16.7116 18.3819 17.0654 18.217 17.353C18.0522 17.6405 17.7452 17.8179 17.4136 17.8179Z' fill='%23F7C96E'/%3E%3Cpath d='M9.1665 14.9258C9.1665 14.8137 9.18609 14.7112 9.22609 14.615C9.26525 14.52 9.3215 14.4375 9.394 14.3683C9.46525 14.2983 9.55317 14.2437 9.654 14.2045C9.75567 14.1662 9.86984 14.1458 9.99734 14.1458C10.1248 14.1458 10.2398 14.1662 10.3423 14.2045C10.4465 14.2437 10.5344 14.2983 10.6057 14.3683C10.6786 14.4375 10.7348 14.52 10.7736 14.615C10.8136 14.7112 10.8332 14.8137 10.8332 14.9258C10.8332 15.0383 10.8136 15.1408 10.7736 15.2341C10.7344 15.3279 10.6782 15.4087 10.6057 15.4791C10.5344 15.5483 10.4465 15.6025 10.3423 15.6425C10.2398 15.6808 10.1248 15.7 9.99734 15.7C9.86942 15.7 9.75567 15.6808 9.654 15.642C9.55317 15.602 9.46525 15.5479 9.394 15.4787C9.32109 15.4083 9.26484 15.3275 9.22609 15.2337C9.18609 15.1404 9.1665 15.0379 9.1665 14.9258ZM10.6165 13.2H9.37775L9.20234 8.19995H10.7915L10.6165 13.2Z' fill='%23614205'/%3E%3C/svg%3E%0A");    }
  &.alert-icon-success {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.3332 10.0001C18.3332 14.6022 14.6019 18.3334 9.99984 18.3334C5.39775 18.3334 1.6665 14.6022 1.6665 10.0001C1.6665 5.398 5.39775 1.66675 9.99984 1.66675C14.6019 1.66675 18.3332 5.398 18.3332 10.0001Z' fill='%23ABE8DA'/%3E%3Cpath d='M14.4108 6.07751L8.75664 11.7383L6.42247 9.41085L5.24414 10.5892L8.75831 14.095L15.5896 7.25585L14.4108 6.07751Z' fill='%23089171'/%3E%3C/svg%3E%0A");
  }
}




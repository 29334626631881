.documents-filters-label {
  color: $gray-400;
  width: 130px;
  @extend .typography-default-text-title;

  &.wide {
    width: 165px;
  }
}

.document-table-title {
  .btn {
    justify-content: flex-start;
    width: 100%;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
}

.documentContentCDA {
  h1.h1center {
    display: none;
  }
}
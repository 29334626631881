.vsm {
  color: #003366 !important;
  background-color: #FFFFFF !important;
  font-size: 0.75rem !important;
  padding: 10px !important;

  & img {
    max-width: 100%;
  }

  & a {
    line-height: 130%;
    color: $primary-700 !important;
    font-size: 0.875rem;
    text-decoration: underline !important;
    font-weight: 500;
  }
  & ul {
    margin-inline-start: -20px;
  }

  & ul, & li {
    font-size: 0.75rem !important;
  }

  & hr {
    border-top: 1px solid $gray-200 !important;
    margin-bottom: inherit !important;
    width: 100%;
  }

  & h1 {
    font-size: 0.75rem !important;
    font-weight: bold !important;
    margin-top: 0.75rem !important;
    color: inherit !important;
  }

  & h2, & h2 a {
    margin-bottom: 0.75rem;
    font-size: 0.875rem !important;
    color: $gray-800 !important;
    font-weight: normal;
    margin-top: 0.75rem !important;
    text-decoration: none !important;
  }

  & h3:empty {
    display: none;
  }

  & h3, & h3 a {
    border-radius: 4px;
    padding: 0.75rem 0.50rem 0.75rem 0.50rem;
    background-color: $primary-700;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 1rem !important;
    margin-top: 2rem !important;
    margin-bottom: 1.25rem !important;
    color: white !important;

    & > a {
      text-decoration: none !important;
    }
  }

  & h4, & h4 a {
    font-style: normal;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 120%;
    color: $gray-800;
  }

  & .documentContentCDA {
    width: 100%;
  }

  div > table {
    padding-left: 1rem;
  }

  & table {
    line-height: 10pt !important;
    width: 100% !important;
    border-collapse: separate !important;
    border-spacing: 0px !important;
    border: 0 !important;
  }

  & tr {
    background-color: white !important;
  }

  & td {
    padding: 0.75rem 1rem 0.75rem 1rem;
    border-bottom: 1px solid #DEE5EF;
    vertical-align: top !important;
    color: $gray-800;
    font-style: normal;
    font-weight: normal;
  }

  & .h1center {
    font-size: 0.75rem !important;
    font-weight: bold !important;
    text-align: center !important;
    width: 100%;
  }

  & .narr_table {
    width: 100% !important;
  }

  & .narr_tr {
    background-color: $primary-100 !important;
  }

  & .narr_th {
    padding: 0.75rem 1rem 0.75rem 1rem;
    border-bottom: 1px solid #DEE5EF;
    background-color: white !important;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
  }

  & .td_label {
    font-weight: bold !important;
    color: white !important;
  }

  &.hide-meta .header_table, &.hide-meta br, &.hide-meta h1 {
    display: none !important;
  }

  /**
  Métadonnée
   */

  td[bgcolor$="#3399ff"] {
    font-style: italic;
    background-color: $white;

    & .td_label {
      color: $primary-700 !important;
    }
  }

  & .header_table {
    padding-left: 0px!important;
    table-layout: fixed;
  }

}

.preview-container-lg {
  max-width: 424px;
  .vsm {
    max-height: 610px;
    overflow-x: hidden;
    overflow-y: auto;
    max-width: 424px;
    font-size: 0.50rem !important;

    & a {
      font-size: 0.625rem;
    }
    & ul, & li {
      font-size: 0.50rem !important;
    }
    & h1 {
      font-size: 0.50rem !important;
      margin-top: 0.50rem !important;
    }
    & h2, & h2 a {
      margin-bottom: 0.50rem;
      font-size: 0.625rem !important;
      margin-top: 0.50rem !important;
    }

    & h3, & h3 a {
      border-radius: 4px;
      padding: 0.35rem 0.20rem;
      font-size: 0.75rem !important;
      margin-top: 1rem !important;
      margin-bottom: 0.825rem !important;
    }
    & h4, & h4 a {
      margin-top: 0.50rem;
      font-size: 0.50rem;
    }
    div > table {
      padding-left: 0.50rem;
    }

    & td {
      padding: 0.35rem 0.50rem;
    }
    & .h1center {
      font-size: 0.50rem !important;
    }
    & .narr_th {
      padding: 0.35rem 0.50rem;
      font-size: 0.50rem;
    }

    & ~ .zoom-button {
      top: auto !important;
      bottom: 10px !important;
    }
  }
}
.document .details .preview {
  max-width: 424px;
  .vsm {
    width: calc(150px * (21 / 29.7)) !important;
    height: 150px !important;
    overflow: hidden;
    font-size: 0.10rem !important;
    line-height: 30% !important;

    & hr {
      margin-bottom: 0.10rem;
      margin-top: 0.10rem;
    }

    & a {
      font-size: 0.15rem;
    }
    & ul {
      margin-block-start: 0.10rem;
      margin-inline-start: 0.10rem;
      padding-inline-start: 0.10rem;
    }
    & ul, & li {
      font-size: 0.10rem !important;
      margin-bottom: 0.10rem;
    }
    & h1 {
      font-size: 0.10rem !important;
      margin-top: 0.10rem !important;
    }
    & h2, & h2 a {
      margin-bottom: 0.10rem;
      font-size: 0.10rem !important;
      margin-top: 0.10rem !important;
    }

    & h3, & h3 a {
      border-radius: 4px;
      padding: 0.10rem 0.10rem;
      font-size: 0.10rem !important;
      margin-top: 0.10rem !important;
      margin-bottom: 0.10rem !important;
    }
    & h4, & h4 a {
      margin-top: 0.10rem;
      margin-bottom: 0.10rem !important;
      font-size: 0.10rem;
    }
    div > table {
      padding-left: 0.10rem;
    }

    & table {
      line-height: 30% !important;
    }

    & td {
      padding: 0.10rem 0.10rem;
    }
    & .h1center {
      font-size: 0.10rem !important;
    }
    & .narr_th {
      padding: 0.10rem 0.10rem;
      font-size: 0.10rem;
    }
  }
}
